@import url(https://fonts.googleapis.com/css2?family=Baskervville+SC&family=Inconsolata:wght@200..900&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&family=Mulish:ital,wght@0,200..1000;1,200..1000&display=swap);
.App {
  text-align: center;
  width: 100%;
}

.app-back {
  width: 100%;
}

.footer{
  padding: 0.5em;
  color: white;
  text-align: center;
  width: 100%;
  font-size: 16px;
}

.footer p {
  margin: 0;
  padding: 0;
}

.footer a{
  color: var(--accent-check);
}

.fade-in-y {
  opacity: 0;
  position: relative;
  transform: translateY(50px);
  transition: opacity 1.5s ease-out, transform 0.6s ease-out;
}

.fade-in-y.visible {
  opacity: 1;
  transform: translateY(0);
}

.fade-in-x {
  opacity: 0;
  position: relative;
  transform: translatex(200px);
  transition: opacity 1s ease-out, transform 0.6s ease-out;
}

.fade-in-x.visible {
  opacity: 1;
  transform: translateY(0);
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media screen and (max-width: 768px) {
  .footer{
    font-size: 12px;
  }
}
.navbar{
  background-color: var(--accent-check) !important;
  float: right;
  position: fixed;
  width: 100%;
  z-index: 1000;
  padding: 5px;
}

.navbar-collapse{
  justify-content: right !important;
}

.brandname{
  color: var(--accent-check);
  font-weight: bolder;
}

.nav-link{
  margin: 0 10px 0 10px;
  color: var(--dark-color) !important;
  font-weight: 500;
  letter-spacing: 2px;
  transition: color 0.3s ease;
}

.nav-link.active {
  color: var(--light-color) !important;
}

.nav-link:hover{
  color: var(--light-color) !important;
  transition: color 0.3s ease;
}

.resume-button{
  background-color: var(--light-color) !important;
  border-radius: 5px;
  border: none;
  margin:  0 10px 0 10px;
  padding: 2px 20px;
  color: var(--dark-color) !important;
  letter-spacing: 1px;
  transition: color 0.8s ease;
  transition: background-color 0.8s ease;
}

.btn-primary:hover{
  box-shadow: none !important;
}

.navbar-toggler{
  border: 0 !important;
}

@media (max-width: 992px) {
  .navbar{
    background-color: var(--accent-check) !important;
    padding: 2px;
  }
  .navbar-collapse{
    justify-content: left !important;
    transition: height 0.3s ease-in-out !important;
    will-change: height;
  }

  .nav-link{
    margin: 10px 5px;
    color: var(--dark-color) !important;
    font-weight: bolder;
    letter-spacing: 2px;
    font-size: 14px;
    border-bottom: 1px solid var(--light-color);
    width: 100vh;
  }

  .resume-button{
    background-color: var(--light-color) !important;
    border: none !important;
    outline: none !important;
    margin:  5px 5px;
    padding: 2px 8px;
  }
  
}
.main {
  min-height: 100vh;
  background-color: #000;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: center;
  padding: 50px;
}

.icon-contact-check {
  align-items: center;
  align-content: center;
  color: #000 !important;
}

.socail-icon-contact-check {
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 30px;
  grid-gap: 20px;
  gap: 20px;
  align-items: center;
  margin-top: 0.5em;
}

.hi-check {
  color: var(--light-color) !important;
  margin: 0;
}

.name {
  letter-spacing: 10px;
  font-size: 36px;
  height: 36px;
  margin-bottom: 10px;
  font-weight: 500;
  color: var(--accent-check);
  text-align: left;
  -webkit-animation: shring-animation 8s ease-in-out 1;
          animation: shring-animation 8s ease-in-out 1;

  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  max-width: 0;
  -webkit-animation: typing 5s steps(2500, end) forwards;
          animation: typing 5s steps(2500, end) forwards;
}

@-webkit-keyframes typing {
  from {
    max-width: 0;
  }
  to {
    max-width: 100%;
  }
}

@keyframes typing {
  from {
    max-width: 0;
  }
  to {
    max-width: 100%;
  }
}

.container {
  width: 80%;
}

.pro-container {
  border-radius: 20px;
  background-color: var(--light-color);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 5% 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.intro-para a {
  color: var(--dark-color);
  text-decoration: underline;
  font-weight: 400;
}

.intro-para {
  text-align: center;
  padding: 0 !important;
  margin: 0 !important;
  font-weight: 500;
  /* text-shadow:  0px 2px 5px rgba(0,0,0,0.2); */
}

.intro {
  text-align: left;
  color: white !important;
}

.profileImg {
  width: 180px;
  height: auto;
  border-radius: 100%;
  object-fit: cover;
  margin-bottom: 1.5em;
  outline: 3px solid white;
  outline-offset: -10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.scroll-down-div {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 1px;
  left: 50%;
  transform: translate(-50%);
}

.scroll-down-container {
  height: 45px;
  width: 45px;
  margin-top: 0;
  border-radius: 60px;
  text-align: center;
  -webkit-animation: scrollDwon 1.5s infinite;
          animation: scrollDwon 1.5s infinite;
}

.scroll-down-action {
  width: 25px;
  height: 25px;
  border-left: 5px dashed var(--accent-check);
  border-bottom: 5px dashed var(--accent-check);
  transform: rotate(-45deg) translate(50%);
}

@-webkit-keyframes scrollDwon {
  0% {
    transform: translate(0);
  }

  20% {
    transform: translateY(15px);
  }

  40% {
    transform: translate(0);
  }
}

@keyframes scrollDwon {
  0% {
    transform: translate(0);
  }

  20% {
    transform: translateY(15px);
  }

  40% {
    transform: translate(0);
  }
}

/* Responsive Design */
@media screen and (max-width: 768px) {

  .main {
    min-height: 100vh;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 20px 50px 20px;
  }
  


  .container {
    width: 100%;
  }


  .name {
    letter-spacing: 5px;
    font-size: 24px;
  }

  .profileImg {
    width: 150px;
  }

  .socail-icon-contact-check {
    font-size: 24px;
    grid-gap: 15px;
    gap: 15px;
  }

  .pro-container {
    padding: 8% 5%;
  }
}

@media screen and (max-width: 480px) {

  .scroll-down-div{
    display: none;
  }

  .main {
    min-height: 100vh;
    background-color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 20px 50px 20px;
  }

  .name {
    letter-spacing: 8px;
    font-size: 26px;
    text-align: center;
    -webkit-animation: typing 2.5s steps(1000, end) forwards;
            animation: typing 2.5s steps(1000, end) forwards;
  }

  .intro {
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    color: white !important;
  }

  .intro-para {
    font-size: 12.5px;
    line-height: 1.4;
    font-weight: 500;
    text-align: center;
  }

  .profileImg {
    width: 120px;
    outline: 2px solid white;
    outline-offset: -5px;
  }

  .socail-icon-contact-check {
    font-size: 20px;
    grid-gap: 10px;
    gap: 10px;
  }

  .pro-container {
    padding: 3% 5%;
  }


  @media screen and (max-width: 390px) {

    .main {
      padding: 60px 20px 50px 20px;
    }

    .pro-container {
      padding: 3% 5%;
    }

    .intro-para {
      font-size: 12px;
    }
  }
}
.skill-box {
  margin: 0 0 8em 0;
}

.skill-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 25px;
  gap: 25px;
  margin-bottom: 20px;
  color: #fff;
  padding: 2% 5% 0 5%;
}

.skill-details {
  background-color: #fff;
  color: #fff;
  padding: 5px;
  border-radius: 20px;
  text-align: center;
  width: 100px;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.skill-details:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.skill-img {
  width: 50px;
  height: 50px;
  margin-bottom: 15px;
  border-radius: 50%;
  padding: 5px;
}

.skill-name {
  font-size: 1rem;
  font-weight: 500;
  color: #000;
}

@media (max-width: 500px) {

  .skill-name {
    font-size: 12.5px;
  }

  .skill-container {
    grid-gap: 15px;
    gap: 15px;
    padding: 2% 1% 0 1%;
  }

  .skill-details {
    
    padding: 0;
   
  }
}

.project-box {}

.project-container {
  background-color: #000;
  color: #000;
  text-align: justify;
  padding: 20px;
}

.card-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  grid-gap: 20px;
  gap: 20px;
}

.card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  background-color: var(--accent-check);
  border-radius: 20px;
  width: 500px;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 1px 1px 15px gray;
}

.project-name {
  color: #000;
  font-weight: bolder;
  margin-bottom: 3%;
  font-size: 16px;
  text-shadow: 0px 4px 7px rgba(0, 0, 0, 0.2);
}

.project-description {
  text-align: justify;
  font-size: 13px;
  line-height: 1.5;
  font-weight: 500;
}

.project-link:hover {
  transform: scaleX(1.1);
}

.project-details {
  background-color: #fff;
  color: #000000;
  padding: 30px 20px 0 20px;
  border-radius: 20px;
  height: 15em;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.project-tech {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 1em;
  justify-content: space-around;
  font-size: 14px;
  color: #000;
  font-weight: bold;
}

.tech-name {
  margin: 5% 0 0 0;
  text-align: center;
  margin: 2% 0 2% 0;
  letter-spacing: 1px;
}

.project-link {
  float: left;
  color: var(--accent-check) !important;
  font-size: 14px;
  font-weight: bolder;
  text-decoration: underline;
}

.project {
  text-align: center;
  margin: 0 0rem 0 0rem;
  height: 100%;
  position: relative;
}

.projectLocation {
  font-size: 10px !important;
  margin: 0 !important;
  text-transform: uppercase;
}

.projectHeading {
  padding-bottom: 1em;
  font-size: 36px;
  color: var(--nameColor);
}

.col-sm-project {
  margin: 0 0 2rem 0 !important;
  padding: 0 !important;
  height: 100% !important;
}

.project-icon {
  font-size: 18px !important;
}

.small-screen-project {
  display: none;
}



@media (max-width: 1050px) {

  .project-box {
    /* margin: 4em 1em 0 1em !important; */
    /* padding: 5px; */
  }

  .project-details {
    padding: 40px 20px 0 20px;
    height: 20em;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .project-link {
    position: relative;
  }

  .card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    background-color: var(--accent-check);
    border-radius: 20px;
    width: 400px;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .project-tech {
    padding-left: 1em;
    font-size: 12px;
  }

}

@media (max-width: 900px) {

  .project-box {
  }

  .project-details {
    padding: 40px 20px 0 20px;
    height: 20em;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .project-link {
    position: relative;
  }

  .card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    background-color: var(--accent-check);
    border-radius: 20px;
    width: 350px;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .project-tech {
    padding-left: 1em;
    font-size: 12px;
  }

}



@media (max-width: 770px) {

  .project-box {
    outline: 0;
    outline-offset: 0;
  }

  .card-container {
    display: none;
  }

  .project-arrow {
    position: absolute;
    right: 2%;
    color: var(--accent-check);
    transition: transform 0.3s ease-in-out;
  }

  .project-arrow.rotate {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
  }

  .project-tech {
    display: none;
  }

  .small-screen-project {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .accordian-project-link{
    color: var(--accent-check);
    position: absolute;
    bottom: 30px;
  }

  .accordion-collapse {
    transition: height 0.3s ease-in-out !important;
    will-change: height;
  }
  

  .accordian-project-tech{
    font-weight: bold;
    position: absolute;
    bottom: 10px;
  }

  .accordian-container {
    /* padding: 1em !important; */
    margin: 1em;
    box-sizing: border-box;
    border-bottom: 1px solid var(--accent-check);
  }

  .accordion-header {
    width: 100%;
    font-size: 16px !important;
  }

  .accordion-button {
    width: 100%;
    padding: 0.5em 0 0 0 !important;
    border: none;
    border-radius: 10px !important;
    text-align: left;
    background-color: transparent;
    color: var(--light-color);
  }

  .accordion-body {
    background-color: var(--light-color) !important;
    padding: 1em;
    font-size: 12.5px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    border-radius: 10px;
    height: 260px;
    transition: max-height 3s ease-in-out !important; 
    margin: 1em;
  }

  .card {
    width: 90%;

  }

  .project-link {
    margin-top: 0.5em;
  }
}

@media (max-width: 380px) {
  .card-container {
    display: none;
  }

  .project-tech {
    display: none;
  }

  .accordion-body {
    height: 280px;
  }

  .project-link {
    margin-top: 0.5em;
  }
}
.timeline-box {
    margin-top: 8em;
    width: 100%;
    overflow: hidden;
}

.timeline-container {
    overflow-x: scroll;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    scroll-behavior: smooth;
}

.timeline {
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    scroll-behavior: smooth;
    width: 100%;
}

.continue-line {
    width: 15em;
    margin-top: 25px;
    border: 2px solid white;
    height: 1px;
}

.timeline-content {
    width: 100%; 
    max-width: 100vw;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s, box-shadow 0.3s;
    overflow-x: auto;
    scrollbar-width: thin;
    scrollbar-color: var(--scroll-color) #ffffff;
    scroll-behavior: smooth;
    white-space: nowrap;
    padding-bottom: 20px;
}

.timeline-text {
    margin-top: 1.5em;
    display: flex;
    min-width: 0;
}

.timeline-content::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}

.timeline-content::-webkit-scrollbar-track {
    background: #ffffff;
}

.timeline-content::-webkit-scrollbar-thumb {
    background-color: var(--scroll-color);
    border-radius: 3px;
}

.timeline-content::-webkit-scrollbar-thumb:hover {
    background-color: #888;
}

.timeline-event {
    height: 12px;
    width: 12px;
    border: 2px solid white;
    border-radius: 100%;
    background-color: white;
    
}

.next-line {
    margin-top: 25px;
    width: 15em;
    border: 2px solid white;
    height: 1px;
}

.event-description {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.time-description-container {
    padding: 0;
    background-color: var(--light-color);
    border-bottom: 10px solid var(--accent-check);
    border-radius: 20px;
    margin: 0 3em 0 3em;
}


.time-description-container p {
    margin-bottom: 0.5em;
}

.timeline-extra-text {
    font-size: 12px;
    letter-spacing: 0;
    font-weight: 300;
    color: #6c757d;
}

.title-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.under-line {
    display: flex;
    flex-direction: row;
}

.timeline-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 5em 0 10em 0;
}

.timeline-connection {
    border-left: 3px solid var(--light-color);
    height: 20px;
    margin-left: 5px;
}

.event-icon {
    color: var(--dark-color) !important;
    font-size: 26px !important;
    margin-bottom: 2%;
}

.event-date {
    font-size: 18px;
    letter-spacing: 1px;
    color: var(--accent-check);
}


.instruction-text {
    margin-top: 1em;
    margin-left: 0.5em;
    height: 20px;
    color: var(--accent-check);
    opacity: 0.8;
}

.nextButton {
    margin-left: 5px;
    -webkit-animation: goNext 1s infinite;
            animation: goNext 1s infinite;
    text-align: center;
    align-items: center;
}

@-webkit-keyframes goNext {
    0% {
        transform: translateX(5px);
    }


    100% {
        transform: translateX(0);
    }
}

@keyframes goNext {
    0% {
        transform: translateX(5px);
    }


    100% {
        transform: translateX(0);
    }
}

.time-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--light-color);
    padding: 5% !important;
    border-radius: 20px;

  }
  
  .event-icon {
    font-size: 24px;
    margin-bottom: 10px;
    color: var(--scoll-color);
  }
  
  .time-description p {
    margin: 0;
    padding: 0;
    /* color: #333; */
    font-size: 14px;
  }
  
  .timeline-extra-text {
    margin-top: 5px;
    color: #6c757d;
    font-style: italic;
    font-size: 12px !important;
  }

  .timeline-position{
    font-size: 14px !important;
  }
  
  .title-description {
    margin-top: 10px;
  }
  
  .title-description p {
    margin: 0;
    padding: 0;
    color: #666;
    font-size: 12px;
  }
  
  .title-description p:first-child {
    font-weight: bold;
    color: #444;
  }
  

@media (max-width: 500px) {
    .next-line {
        width: 12em;
    }

    .continue-line {
        width: 12em;
    }

    .time-description-container {
        padding: 5px 0 5px 0;
        border-radius: 10px;
        background-color: white;
        margin: 0 1em 0 1em;
    }

    .time-description-container p {
        margin-bottom: 0.1em;
    }

    .timeline-content {
        border-radius: 0;
        margin-left: 0;
        margin-right: 0;
    }


    .time-description {
        padding: 0;
        font-size: 12px;
    }

}
.contact {
  display: flex;
  flex-direction: row;
  justify-content:center;
  grid-gap: 20em;
  gap: 20em;
  background-color: var(--light-color);
  padding: 4em 0 4em 0;
  letter-spacing: 1px;
  border-radius: 20px;
  align-items: center;
}

.contact-heading {
  color: var(--accent-check);
  font-size: 40px;
  font-weight: bolder;
  text-align: left;
  text-shadow: 0px 2px 2px rgba(0,0,0,0.2);

}

.social-connect-message{
  color: var(--accent-check);
  font-size: 16px;
  text-shadow: 0px 4px 7px rgba(0,0,0,0.2);
}

.contact-message {
  color:  var(--accent-check);
  text-align: left;
  font-size: 18px;
  font-weight: 500;
  margin: 5% 0 0 0;
  line-height: 1.5;
  max-width: 600px;
}

.mail-contact {
  margin: 0.5em 0 3em 0 !important;
}

.contact-mail-address {
  color: var(--dark-color);
  font-size: 18px;
  letter-spacing: 1px;
  text-decoration: underline;
  margin: 2em 0 1em 0;
  cursor: pointer;
  transition: color 0.5s ease;
}

.contact-mail-address:hover {
  color:  var(--accent-check);
  transition: color 0.5s ease;
}

.socail-icon-contact {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  grid-gap: 1em;
  gap: 1em;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.social-icon-row{
  margin-top: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  grid-gap: 1em;
  gap: 1em;
  flex-direction: row;
}

.social-icon{
  background-color: #000;
  width: 8rem;
  height: 8rem;
  border: none;
  border-radius: 20px;
  align-items: center;
  text-align: center;
  align-content: center;
  color: var(--accent-check);
  transition: color 0.8s ease;
  /* box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px; */
  box-shadow: 0px 4px 7px rgba(0,0,0,0.2);;
  
}


.social-icon:hover{
  box-shadow: none;
  color: var(--light-color);
  transition: color 0.3s ease;
}


.icon-contact {
  font-size: 3em;
  cursor: pointer;
  transition: color 0.1s ease;
}

.icon-contact:hover {
  color: var(--light-color);
  transform: scale(1.1);
  cursor: pointer;
}

@media (max-width: 1050px){

  .contact{
    padding: 4em 4em 4em 4em;
    margin: 0 10px 0 10px;
    grid-gap: 8em;
    gap: 8em;
  }

  .contact-message {
    text-align: left;
    font-size: 16px;
  }

  .socail-icon-contact {
    flex-direction: row;
    grid-gap: 10px;
    gap: 10px;
  }

  .social-icon{
    width: 6em;
    height: 6rem;
  }

  .icon-contact {
    font-size: 2em;
  }

  .social-icon-row{
    grid-gap: 10px;
    gap: 10px;
  }

  .social-connect-message{
    text-align: center;
  }
}

@media (max-width: 900px){
  .contact {
    display: flex;
    flex-direction: column;
    grid-gap: 2em;
    gap: 2em;
  }

  .socail-icon-contact {
    margin-top: 0.5em;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
  }


  .social-icon{
    width: 5em;
    height: 5rem;
  }

  .icon-contact {
    font-size: 2em;
  }


  .contact-heading {
    text-align: center;
  }

  .contact-message {
    text-align: center;
  }

  .social-connect-message{
    display: none;
  }
}


@media (max-width: 500px){
  .contact {
    display: flex;
    flex-direction: column;
    grid-gap: 3em;
    gap: 3em;
    padding: 4em 1em 4em 1em;
    margin: 0 20px 0 20px;
  }

  .socail-icon-contact {
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
  }

  .social-icon{
    width: 4em;
    height: 4rem;
  }

  .icon-contact {
    font-size: 1.5em;
  }


  .contact-heading {
    text-align: center;
    font-size: 32px;
  }


  .contact-message {
    font-size: 14px;
    padding: 0 0 0 0;
    font-weight: bold;
    text-align: center;
  }

  .contact-mail-address {
    font-size: 14px;
  }

  .social-connect-message{
    display: none;
  }
}
:root {
  color-scheme: light;
  --accent-check: #fca311;
  --dark-color: #000;
  --light-color: #fff;
}


html, body{
  min-height: 100vh;
  background-color: #000;
  scroll-behavior: smooth;
  font-family: "Inconsolata", monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  max-width: 100%;
}


::-webkit-scrollbar-thumb {
  opacity: 0;
}

.div-heading {
  color: #fff !important;
  letter-spacing: 20px;
  text-align: center;
  align-items: center;
  margin: 0 0 1em 1em;
  font-weight: normal;
  font-size: 30px;
}

.btn-primary {
  border-radius: 5px;
  color: black;
  margin-right: 15px !important;
  letter-spacing: 1px;
  text-align: center;
  align-items: center;
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
  background-color: white !important;
}

.btn-primary:hover {
  background-color: #000 !important;
  background-color: var(--dark-color) !important;
  color: #fca311 !important;
  color: var(--accent-check) !important;
  box-shadow: none;
  border: none;
  transition: color  0.8s ease;
  transition: background-color 0.8s ease;
}

.btn-primary:focus {
  outline: none !important;
  border: none !important;
}

@media (max-width: 500px) {
  .div-heading {
    font-size: 28px;
  }
}

@media (max-width: 380px) {
  .div-heading {
    font-size: 24px;
    margin-left: 1em;
    margin-right: 1em;
  }
}

.project-box {}

.project-container {
  background-color: #000;
  color: #000;
  text-align: justify;
  padding: 20px;
}

.card-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.card {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0;
  background-color: var(--accent-check);
  border-radius: 20px;
  width: 500px;
  transition: transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 1px 1px 15px gray;
}

.project-name {
  color: #000;
  font-weight: bolder;
  margin-bottom: 3%;
  font-size: 16px;
  text-shadow: 0px 4px 7px rgba(0, 0, 0, 0.2);
}

.project-description {
  text-align: justify;
  font-size: 13px;
  line-height: 1.5;
  font-weight: 500;
}

.project-link:hover {
  transform: scaleX(1.1);
}

.project-details {
  background-color: #fff;
  color: #000000;
  padding: 30px 20px 0 20px;
  border-radius: 20px;
  height: 15em;
  box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px;
}

.project-tech {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-left: 1em;
  justify-content: space-around;
  font-size: 14px;
  color: #000;
  font-weight: bold;
}

.tech-name {
  margin: 5% 0 0 0;
  text-align: center;
  margin: 2% 0 2% 0;
  letter-spacing: 1px;
}

.project-link {
  float: left;
  color: var(--accent-check) !important;
  font-size: 14px;
  font-weight: bolder;
  text-decoration: underline;
}

.project {
  text-align: center;
  margin: 0 0rem 0 0rem;
  height: 100%;
  position: relative;
}

.projectLocation {
  font-size: 10px !important;
  margin: 0 !important;
  text-transform: uppercase;
}

.projectHeading {
  padding-bottom: 1em;
  font-size: 36px;
  color: var(--nameColor);
}

.col-sm-project {
  margin: 0 0 2rem 0 !important;
  padding: 0 !important;
  height: 100% !important;
}

.project-icon {
  font-size: 18px !important;
}

.small-screen-project {
  display: none;
}



@media (max-width: 1050px) {

  .project-box {
    /* margin: 4em 1em 0 1em !important; */
    /* padding: 5px; */
  }

  .project-details {
    padding: 40px 20px 0 20px;
    height: 20em;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .project-link {
    position: relative;
  }

  .card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    background-color: var(--accent-check);
    border-radius: 20px;
    width: 400px;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .project-tech {
    padding-left: 1em;
    font-size: 12px;
  }

}

@media (max-width: 900px) {

  .project-box {
  }

  .project-details {
    padding: 40px 20px 0 20px;
    height: 20em;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }

  .project-link {
    position: relative;
  }

  .card {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0;
    background-color: var(--accent-check);
    border-radius: 20px;
    width: 350px;
    transition: transform 0.3s, box-shadow 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .project-tech {
    padding-left: 1em;
    font-size: 12px;
  }

}



@media (max-width: 770px) {

  .project-box {
    outline: 0;
    outline-offset: 0;
  }

  .card-container {
    display: none;
  }

  .project-arrow {
    position: absolute;
    right: 2%;
    color: var(--accent-check);
    transition: transform 0.3s ease-in-out;
  }

  .project-arrow.rotate {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
  }

  .project-tech {
    display: none;
  }

  .small-screen-project {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .accordian-project-link{
    color: var(--accent-check);
    position: absolute;
    bottom: 30px;
  }

  .accordion-collapse {
    transition: height 0.3s ease-in-out !important;
    will-change: height;
  }
  

  .accordian-project-tech{
    font-weight: bold;
    position: absolute;
    bottom: 10px;
  }

  .accordian-container {
    /* padding: 1em !important; */
    margin: 1em;
    box-sizing: border-box;
    border-bottom: 1px solid var(--accent-check);
  }

  .accordion-header {
    width: 100%;
    font-size: 16px !important;
  }

  .accordion-button {
    width: 100%;
    padding: 0.5em 0 0 0 !important;
    border: none;
    border-radius: 10px !important;
    text-align: left;
    background-color: transparent;
    color: var(--light-color);
  }

  .accordion-body {
    background-color: var(--light-color) !important;
    padding: 1em;
    font-size: 12.5px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 10px;
    height: 260px;
    transition: max-height 3s ease-in-out !important; 
    margin: 1em;
  }

  .card {
    width: 90%;

  }

  .project-link {
    margin-top: 0.5em;
  }
}

@media (max-width: 380px) {
  .card-container {
    display: none;
  }

  .project-tech {
    display: none;
  }

  .accordion-body {
    height: 280px;
  }

  .project-link {
    margin-top: 0.5em;
  }
}